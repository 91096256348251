<template>
  <div class="senderrand-nav">
    <div class="container">
      <b-navbar toggleable="lg" type="dark">
        <b-navbar-brand to="/" class="senderrand-logo-wrapper">
          <img src="@/assets/Logo.svg" alt="senderrand logo">
        </b-navbar-brand>

        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

        <b-collapse id="nav-collapse" is-nav>
          <b-navbar-nav class="ml-auto">
            <b-nav-item-dropdown text="More">
              <b-dropdown-item to="/runners">Individual Runner</b-dropdown-item>
              <b-dropdown-item to="/business">Logistic Business</b-dropdown-item>
              <b-dropdown-item to="/privacy-policy">Privacy Policy</b-dropdown-item>
              <b-dropdown-item to="/contact">Contact Support</b-dropdown-item>
            </b-nav-item-dropdown>
            <b-nav-item v-if="this.$router.history.current.path.includes('business')" to="/coming-soon">Login</b-nav-item>
<!--            <button class="btn btn-white">Get Started</button>-->
          </b-navbar-nav>
        </b-collapse>
      </b-navbar>
    </div>
  </div>
</template>

<script>
export default {};
</script>
<style lang="scss" scoped>
.navbar {
  padding: 0.5rem 0;
}
.navbar-dark .navbar-nav {
  .nav-link {
    color: $white;
    margin-left: 10px;
  }
}
.senderrand-logo-wrapper {
  max-width: 155px;
  height: 50px;
  img {
    width: 95%;
    height: 95%;
    object-fit: contain;
    object-position: left;
  }
}
</style>
