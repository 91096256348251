<template>
  <div class="wrap-step">
    <div class="step-item-view" v-for="(step, i) in steps" :key="i">
      <div class="step-image animate__animated animate__zoomIn">
        <img :src="step.img_url" alt="step image" />
      </div>
      <div class="step-item-info">
        <h3>
          <span class="step-counter">{{ step.count }}</span>
          {{ step.header_title }}
        </h3>
        <p>{{ step.desc }}</p>
        <p>
          <b>{{ step.sub_desc }}</b>
        </p>
        <div
          class="step-btn-view"
          v-if="step.btn_app_store || step.btn_play_store || step.btn_link"
        >
          <div
            class="row-btns"
            v-if="step.btn_app_store && step.btn_play_store"
          >
            <div class="btn-set">
              <button
                class="btn-app play-store"
                @click="appLink(step.btn_app_store)"
              >
                <span class="-icon"><i class="play-store-icon"></i></span>
              </button>
              <button
                class="btn-app prime app-store"
                @click="appLink(step.btn_play_store)"
              >
                <span class="-icon"><i class="app-store-icon"></i></span>
              </button>
            </div>
          </div>
          <div class="btn-set" v-if="step.btn_link">
            <button
              @click="handleOption(step.btn_title, step.btn_link)"
              class="btn btn-primary"
            >
              {{ step.btn_title }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["steps"],
  methods: {
    handleOption(option, link) {
      console.log(option, link);
      // $this.$emit(this.handleOption, { btnName: option, btnLink: link})
    },
    appLink(link) {
      console.log(link);
    },
  },
};
</script>

<style>
</style>