<template>
  <header class="app-header">
    <CustomNav />
    <div class="banner-container">
      <div class="container">
        <div class="row">
          <div class="col-md-6">
            <div class="page send-errand-caption">
              <h1 class="animate__animated animate__slideInDown">{{ title }}</h1>
              <p>
                {{ paragraph }}
              </p>
              <div class="btn-set" v-if="business">
                <button class="btn btn-white">Register Your Business</button>
              </div>
              <div class="btn-set animate__animated animate__slideInUp" v-else>
                <button class="btn-app play-store" @click="playStore">
                  <span class="-icon"><i class="play-store-icon"></i></span>
                </button>
                <button class="btn-app app-store" @click="appStore">
                  <span class="-icon"><i class="app-store-icon"></i></span>
                </button>
              </div>
            </div>
          </div>
          <div class="col-md-6 animate__animated animate__slideInRight">
            <div class="image-wrapper" :class="{'runners' : !business}" > 
              <img :src="src" :alt="title" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import CustomNav from "./CustomNav.vue";
export default {
  components: {
    CustomNav,
  },
  props: ["title", "paragraph", "src", "business"],
  methods: {
    playStore() {
      window.open('https://play.google.com/store/apps/details?id=com.senderrand.runner', '_blank');
    },
    appStore() {},
  },
};
</script>
<style lang="scss" scoped>
.app-header {
  min-height: 100vh;
  background-color: $primary-color;
}
.banner-container {
  overflow: hidden;
}
.image-wrapper {
  display: flex;
  align-items: center;
  height: 85vh;
  transition: $transition;
  &.runners {
    img {
      transform: scale(1.2);
      position: relative;
      top: 3.5em;
    } 
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
    transition: $transition;
    transform: scale(1.2);
  }
}
@media (max-width: 720px) {
  .image-wrapper {
    display: none;
  }
}
</style>