<template>
  <div class="page-view">
    <CustomBanner
      title="Send Errand for Logistic Companies"
      paragraph="Onboard your fleets on send errand and gain access to a robust dashboard to manage all your fleet and errand runners. Relax and watch your business grow."
      appStorelink=""
      playStorelink=""
      :src="image"
      :business="true"
    />
    <main id="how-it-works">
      <section>
        <div class="container">
          <div class="section-header">
            <h3>How It Works</h3>
            <p>Step by Step instructions on registering your Fleet</p>
          </div>
          <div class="row">
            <div class="col-md-12">
              <StepView :steps="steps" />
            </div>
          </div>
        </div>
      </section>
      <section class="bg-prime reversed">
        <div class="container">
          <div class="row">
            <div class="col-md-6">
              <div class="infor-content">
                <h2>Why Send Errand?</h2>
                <p>
                  Send Errand helps users to directly order from stores of their
                  own choice and also allows them to send our qualified errand
                  runners on shopping errands. Users can track the entire
                  process until delivery.
                </p>
                <p>
                  Send Errand helps businesses to make more sales by connecting
                  them to online customers for direct transactions.
                </p>
              </div>
            </div>
            <div class="col-md-6">
              <div class="infor-image animate__animated animate__slideInRight">
                <img src="@/assets/img/business/senderrand-business-load.png" alt="">
              </div>
            </div>
          </div>
        </div>
      </section>
      <VideoSection />
    </main>
    <Footer />
  </div>
</template>

<script>
import CustomBanner from "../../../components/CustomBanner.vue";
import Footer from "../../../components/Footer.vue";
import StepView from "../../../components/StepView.vue";
import VideoSection from "../../../components/VideoSection.vue";
export default {
  components: { CustomBanner, Footer, VideoSection, StepView },
  data: () => {
    return {
      image: require("../../../assets/img/senderrand-logistics-banner-image.svg"),
      steps: [
        {
          count: 1,
          header_title: "Register Your Business",
          img_url: require("../../../assets/img/business/senderrand-business-step-1.svg"),
          desc: "Register your business to access out Web Based Dashboard. Enlist your store now and make more in your earnings and customer reach",
          sub_desc: "Start earning big, register for Free!",
          btn_title: "Register Your Business",
          btn_link: "register-business",
          btn_app_store: "",
          btn_play_store: "",
        },
        {
          count: 2,
          header_title: "Invite your Runners",
          img_url: require("../../../assets/img/business/senderrand-business-step-2.svg"),
          desc: "Send an Invitation to your Staffs and we would reach out to them with the on boarding processes.",
          sub_desc: "Start earning big, Register for Free!",
          btn_title: "",
          btn_link: "",
          btn_app_store: "",
          btn_play_store: "",
        },
        {
          count: 3,
          header_title: "Start Processing Orders",
          img_url: require("../../../assets/img/business/senderrand-business-step-3.svg"),
          desc: "Accept and Process Orders, then assign them to your runners/staffs.",
          sub_desc: "Start earning big, register for Free!",
          btn_title: "Register Your Business",
          btn_link: "register-business",
          btn_app_store: "",
          btn_play_store: "",
        },
      ],
    };
  },
  methods: {
    handleOption(option, btn_link) {
      console.log(option, btn_link);
    },
  },
};
</script>
<style lang="scss" scoped>
</style>