<template>
  <footer>
    <div class="container">
      <div class="row">
        <div class="col-md-4">
          <div class="footer-info">
            <div class="logo-wrapper">
              <img src="@/assets/Logo.svg" alt="senderrand-logo" />
            </div>
            <address>
              A5 building, Dubai Digital Park. <br />
              Dubai, UAE.
            </address>
            <address>
              12F Tola Adewunmi St, Maryland, Lagos, Nigeria.
            </address>
            <a href="mailto:info@senderrand.com">info@senderrand.com</a>
          </div>
        </div>
        <div class="col-md-4">
          <div class="row">
            <div class="col-md-6">
              <h4>Company</h4>
              <ul>
                <li><router-link to="/business">For Business</router-link></li>
                <li><router-link to="/runners">For Runners</router-link></li>
                <li><router-link to="/coming-soon">How It Works</router-link></li>
                <!--                <li><router-link to="/coming-soon">About Us</router-link></li>-->
              </ul>
            </div>
            <div class="col-md-6">
              <h4>Legal</h4>
              <ul>
                <li><router-link to="/coming-soon">Terms and Conditions</router-link></li>
                <li><router-link to="/coming-soon">Privacy Policy</router-link></li>
                <li><router-link to="/coming-soon">Careers</router-link></li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <h4>Subscribe to Our Newsletter</h4>
          <form action="">
            <div class="form-field form-group">
              <input type="text" class="form-control" />
              <button class="btn-send">
                <font-awesome-icon :icon="faPaperPlane" />
              </button>
            </div>
          </form>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="copyright">
            <p>Copyright © {{ new Date().getFullYear() }} <span class=" capitalize">SEND ERRAND TECHNOLOGIES GLOBAL
                LIMITED</span> All rights
              reserved.
            </p>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";
export default {
  components: { FontAwesomeIcon },
  data: () => {
    return {
      faPaperPlane,
    };
  },
};
</script>
<style lang="scss" scoped>
footer {
  min-height: 390px;
  background-color: $primary-dark;
  display: flex;
  align-items: flex-end;
  padding-top: 2em;

  >* {
    color: $white;
  }

  ul {
    list-style: none;
    padding: 0;

  }

  a {
    color: $white;
    display: inline-flex;
    margin-bottom: 10px;
    transition: $transition;

    &:hover {
      opacity: 0.7;
      text-decoration: none;
    }
  }

  h4 {
    font-size: 18px;
    font-weight: 800;
    margin: 1em 0 1.5em;
  }

  .copyright {
    margin-top: 2em;
    border-top: 1px solid #fff;
    padding: 2em 0 1em;
  }

  .logo-wrapper {
    margin-bottom: 1em;
    max-width: 130px;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: left;
    }
  }
}

.form-group {
  position: relative;

  .form-control {
    background-color: rgba($white, 0.2);
    height: 45px;
    border: 1px solid rgba($white, 0.2);
    color: $white;
    padding-left: 20px;
  }

  .btn-send {
    position: absolute;
    top: 0;
    right: 10px;
    width: 35px;
    height: 35px;
    border: none !important;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 22px;
    background-color: transparent !important;
    transition: 0.4s ease-in-out;
    color: $white;
    opacity: 0.6;

    &:hover {
      opacity: 1;
    }
  }
}
</style>