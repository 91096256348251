<template>
  <section class="vid-section">
    <div class="container">
      <div class="vid-info">
        <span class="-icon">
          <i class="earning-icon"></i>
        </span>
        <h5>
          Over 1,000 Runners <br />
          are <span>Earning Big</span> with Send Errand.
        </h5>
      </div>
      <div class="video-wrapper">
        <video ref="player" autoplay>
          <source type="video/mp4" src="@/assets/videos/sample.mp4" />
        </video>
        <button class="btn-play animate__animated animate__slideInUp" @click="toggleVideo">
          <span class="inner-btn">
            <font-awesome-icon :icon="videoPlay ? faPause : faPlay" />
          </span>
        </button>
      </div>
    </div>
  </section>
</template>

<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faPause, faPlay } from "@fortawesome/free-solid-svg-icons";
export default {
  components: {
    FontAwesomeIcon,
  },
  data: () => {
    return {
      currentTime: 0,
      faPause,
      faPlay,
      videoPlay: false,
    };
  },
  methods: {
    toggleVideo() {
      if (this.videoPlay) {
        this.$refs.player.pause();
        this.videoPlay = false;
      } else {
        this.$refs.player.play();
        this.videoPlay = true;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.vid-section {
  min-height: 85vh;
  display: flex;
  align-items: center;
  text-align: center;
  padding: 3em 0;
  h5 {
    font-size: 1.4em;
    margin-bottom: 1.5em;
    font-weight: 700;
    > span {
      color: $primary-color;
    }
  }
  .video-wrapper {
    position: relative;
    width: 80%;
    height: 450px;
    margin: auto;
    border-radius: 20px;
    overflow: hidden;
    video {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    &:hover {
      .btn-play {
        display: block;
      }
    }
    .btn-play {
      position: absolute;
      display: none;
      top: calc(50% - 40px);
      left: calc(50% - 40px);
      transition: $transition;
    }
  }
}
.vid-info {
  width: 40%;
  margin: auto;
  .-icon {
    width: 80px;
    height: 80px;
    margin-bottom: 1em;
  }
}
@media (max-width: 990px) {
  .vid-section {
    min-height: 60vh;
    .video-wrapper {
      width: 100%;
      height: 250px;
    }
    .vid-info {
      width: 100%;
    }
    h5 {
      font-size: 1.2em;
    }
  }
  .btn-play {
    transform: scale(0.8);
  }
}
</style>